import Layout from './components/layout';
import Routes from './routes';

const App = () => {
  return (
    <Layout>
        <Routes />
    </Layout>
  );
}

export default App;
